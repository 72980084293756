var loadedProfessionals = JSON.parse(initialJsonData);
var professionalSettings = JSON.parse(professionalSettings);
var relatedProfessionalsViewModel = ko.mapping.fromJS(loadedProfessionals);
var attorneyLevelOrder = JSON.parse(levelOrder)

var params = parseQueryString(location.search);
if (params.relatedprofessionalspage)
    relatedProfessionalsViewModel.Page(params.relatedprofessionalspage);
if (params.sort)
    relatedProfessionalsViewModel.Sort(params.sort);

relatedProfessionalsViewModel.IsSearchRunning = ko.observable(false);

relatedProfessionalsViewModel.AllProfessionals = ko.computed(function () {
	return this.PinnedProfessionals().concat(this.Professionals());
}, relatedProfessionalsViewModel);

relatedProfessionalsViewModel.QueryString = function () {   
    var queryString = "";
    if (relatedProfessionalsViewModel.Page()) {
        queryString += (queryString != "" ? "&" : "?");
        queryString += "relatedprofessionalspage=" + relatedProfessionalsViewModel.Page();
    }
    if (relatedProfessionalsViewModel.Sort()) {
        queryString += (queryString != "" ? "&" : "?");
        queryString += "sort=" + relatedProfessionalsViewModel.Sort();
    }   
    return queryString;
}

relatedProfessionalsViewModel.SortAll = function(sortby) {
    if( sortby != "Position" && sortby != "LastName"){     
        return 0;
    }        
    if (relatedProfessionalsViewModel.IsSearchRunning())
        return;
    relatedProfessionalsViewModel.IsSearchRunning(true);
    var data = {
        ServiceId: relatedProfessionalsViewModel.CurrentServiceId(),
        Skip: 0,
        Take: relatedProfessionalsViewModel.TakeCount() * relatedProfessionalsViewModel.Page(),
        Sort: sortby   
    }
    $.ajax({
        url: "/api/sitecore/professionals/relatedprofessionals",
        type: "POST",
        dataType: "json",
        contentType: "application/json; charset=utf-8",
        data: JSON.stringify(data),
        cache: false,
        
        success: function (data) {
            var expectedProfessionals = [];
            relatedProfessionalsViewModel.Professionals([]);
            relatedProfessionalsViewModel.Sort(sortby);
            var results = ko.observableArray();
            ko.mapping.fromJS(data.Professionals, {}, results);
            ko.utils.arrayForEach(results(), function (item) {
                expectedProfessionals.push(item);
            });
            relatedProfessionalsViewModel.Professionals(expectedProfessionals);
            var qs = relatedProfessionalsViewModel.QueryString();
            var data = History.getState().data;
            data['qs'] = qs;
            History.replaceState(data, History.options.initialTitle, qs);
            relatedProfessionalsViewModel.IsSearchRunning(false);
        },
        error: function (xhr, textStatus, errorThrown) {
            console.log(xhr);
            console.log("textStatus : " + textStatus);
            console.log("errorThrown : " + errorThrown);
        }
    });
}

relatedProfessionalsViewModel.LoadNextPage = function() {

    if (relatedProfessionalsViewModel.IsSearchRunning())
        return;
    relatedProfessionalsViewModel.IsSearchRunning(true);
    var data = {
        ServiceId: relatedProfessionalsViewModel.CurrentServiceId(),
        Skip: relatedProfessionalsViewModel.Page() * relatedProfessionalsViewModel.TakeCount(),
        Take: relatedProfessionalsViewModel.TakeCount(),
        Sort: relatedProfessionalsViewModel.Sort()      
    }
    $.ajax({
        url: "/api/sitecore/professionals/relatedprofessionals",
        type: "POST",
        dataType: "json",
        contentType: "application/json; charset=utf-8",
        data: JSON.stringify(data),
        cache: false,
        
        success: function (data) {
            var results = ko.observableArray();
            ko.mapping.fromJS(data.Professionals, {}, results);
            ko.utils.arrayForEach(results(), function (item) {
                relatedProfessionalsViewModel.Professionals.push(item);
            });
            relatedProfessionalsViewModel.Page(parseInt(relatedProfessionalsViewModel.Page()) + 1);
            var qs = relatedProfessionalsViewModel.QueryString();
            var data = History.getState().data;
            data['qs'] = qs;
            History.replaceState(data, History.options.initialTitle, qs);
            relatedProfessionalsViewModel.IsSearchRunning(false);
        },
        error: function (xhr, textStatus, errorThrown) {
            console.log(xhr);
            console.log("textStatus : " + textStatus);
            console.log("errorThrown : " + errorThrown);
        }
    });
}

relatedProfessionalsViewModel.Sort.subscribe(function() {
    var qs = relatedProfessionalsViewModel.QueryString();
    var data = History.getState().data;
    data['qs'] = qs;
    History.replaceState(data, History.options.initialTitle, qs);
});

var $relatedProfessionalsContainer = $(".js-related-professionals-binding-container");
if ($relatedProfessionalsContainer.length) {
    ko.applyBindings(relatedProfessionalsViewModel, $relatedProfessionalsContainer.get(0));
} 